import Img1 from "../images/download/appstore.svg";
import Img2 from "../images/download/googleapp.svg";

function Download() {
  return (
    <>
      {/* <section className="download-section">
        <div className="container">
          <div className="download-text">
            <h2>Download our app to get most out of it</h2>
            <p>
              Thrown shy denote ten ladies though ask saw. Or by to he going
              think order event music. Incommode so intention defective at
              convinced. Led income months itself and houses you.
            </p>
            <div className="download-text__btns">
              <img alt="download_img" src={Img2} />
              <img alt="download_img" src={Img1} />
            </div>
          </div>
        </div>
      </section> */}
      <div className="container faq-content__title" id="contact">
        <h2>Ubicacion</h2>
        <div style={{ width: '100%', height: '300px', border: '0' }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d445.0789928507822!2d-65.29932810440452!3d-24.203444489248138!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x941b0f249fae2fff%3A0x2b309cc2534dd58b!2sParabrisas%20y%20Cristales%20Jujuy!5e0!3m2!1sen!2sar!4v1721928251238!5m2!1sen!2sar"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Download;
