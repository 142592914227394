const MarcasModelos = [
  {
    marca: "Ford",
    modelos: [
      {
        nombre: "Fiesta",
        seo: {
          title: "Parabrisas Ford Fiesta - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Ford Fiesta en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Ford Fiesta, repuestos Ford Fiesta, Jujuy",
        },
      },
      {
        nombre: "Focus",
        seo: {
          title: "Parabrisas Ford Focus - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Ford Focus en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Ford Focus, repuestos Ford Focus, Jujuy",
        },
      },
      {
        nombre: "Ranger",
        seo: {
          title: "Parabrisas Ford Ranger - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Ford Ranger en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Ford Ranger, repuestos Ford Ranger, Jujuy",
        },
      },
      {
        nombre: "EcoSport",
        seo: {
          title: "Parabrisas Ford EcoSport - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Ford EcoSport en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Ford EcoSport, repuestos Ford EcoSport, Jujuy",
        },
      },
      {
        nombre: "Ka",
        seo: {
          title: "Parabrisas Ford Ka - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Ford Ka en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Ford Ka, repuestos Ford Ka, Jujuy",
        },
      },
      {
        nombre: "Mondeo",
        seo: {
          title: "Parabrisas Ford Mondeo - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Ford Mondeo en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Ford Mondeo, repuestos Ford Mondeo, Jujuy",
        },
      },
    ],
  },
  {
    marca: "Chevrolet",
    modelos: [
      {
        nombre: "Onix",
        seo: {
          title: "Parabrisas Chevrolet Onix - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Chevrolet Onix en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Chevrolet Onix, repuestos Chevrolet Onix, Jujuy",
        },
      },
      {
        nombre: "Cruze",
        seo: {
          title: "Parabrisas Chevrolet Cruze - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Chevrolet Cruze en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Chevrolet Cruze, repuestos Chevrolet Cruze, Jujuy",
        },
      },
      {
        nombre: "S10",
        seo: {
          title: "Parabrisas Chevrolet S10 - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Chevrolet S10 en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Chevrolet S10, repuestos Chevrolet S10, Jujuy",
        },
      },
      {
        nombre: "Tracker",
        seo: {
          title:
            "Parabrisas Chevrolet Tracker - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Chevrolet Tracker en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Chevrolet Tracker, repuestos Chevrolet Tracker, Jujuy",
        },
      },
      {
        nombre: "Spin",
        seo: {
          title: "Parabrisas Chevrolet Spin - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Chevrolet Spin en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Chevrolet Spin, repuestos Chevrolet Spin, Jujuy",
        },
      },
      {
        nombre: "Corsa",
        seo: {
          title: "Parabrisas Chevrolet Corsa - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Chevrolet Corsa en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Chevrolet Corsa, repuestos Chevrolet Corsa, Jujuy",
        },
      },
      {
        nombre: "Astra",
        seo: {
          title: "Parabrisas Chevrolet Astra - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Chevrolet Astra en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Chevrolet Astra, repuestos Chevrolet Astra, Jujuy",
        },
      },
      {
        nombre: "Celta",
        seo: {
          title: "Parabrisas Chevrolet Celta - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Chevrolet Celta en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Chevrolet Celta, repuestos Chevrolet Celta, Jujuy",
        },
      },
      {
        nombre: "Meriva",
        seo: {
          title: "Parabrisas Chevrolet Meriva - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Chevrolet Meriva en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Chevrolet Meriva, repuestos Chevrolet Meriva, Jujuy",
        },
      },
      {
        nombre: "Zafira",
        seo: {
          title: "Parabrisas Chevrolet Zafira - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Chevrolet Zafira en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Chevrolet Zafira, repuestos Chevrolet Zafira, Jujuy",
        },
      },
    ],
  },
  {
    marca: "Toyota",
    modelos: [
      {
        nombre: "Corolla",
        seo: {
          title: "Parabrisas Toyota Corolla - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Toyota Corolla en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Toyota Corolla, repuestos Toyota Corolla, Jujuy",
        },
      },
      {
        nombre: "Hilux",
        seo: {
          title: "Parabrisas Toyota Hilux - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Toyota Hilux en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Toyota Hilux, repuestos Toyota Hilux, Jujuy",
        },
      },
      {
        nombre: "Yaris",
        seo: {
          title: "Parabrisas Toyota Yaris - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Toyota Yaris en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Toyota Yaris, repuestos Toyota Yaris, Jujuy",
        },
      },
      {
        nombre: "Etios",
        seo: {
          title: "Parabrisas Toyota Etios - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Toyota Etios en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Toyota Etios, repuestos Toyota Etios, Jujuy",
        },
      },
      {
        nombre: "Rav4",
        seo: {
          title: "Parabrisas Toyota Rav4 - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Toyota Rav4 en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Toyota Rav4, repuestos Toyota Rav4, Jujuy",
        },
      },
    ],
  },
  {
    marca: "Volkswagen",
    modelos: [
      {
        nombre: "Gol",
        seo: {
          title: "Parabrisas Volkswagen Gol - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Volkswagen Gol en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Volkswagen Gol, repuestos Volkswagen Gol, Jujuy",
        },
      },
      {
        nombre: "Vento",
        seo: {
          title: "Parabrisas Volkswagen Vento - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Volkswagen Vento en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Volkswagen Vento, repuestos Volkswagen Vento, Jujuy",
        },
      },
      {
        nombre: "Amarok",
        seo: {
          title:
            "Parabrisas Volkswagen Amarok - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Volkswagen Amarok en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Volkswagen Amarok, repuestos Volkswagen Amarok, Jujuy",
        },
      },
      {
        nombre: "T-Cross",
        seo: {
          title:
            "Parabrisas Volkswagen T-Cross - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Volkswagen T-Cross en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Volkswagen T-Cross, repuestos Volkswagen T-Cross, Jujuy",
        },
      },
      {
        nombre: "Tiguan",
        seo: {
          title:
            "Parabrisas Volkswagen Tiguan - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Volkswagen Tiguan en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Volkswagen Tiguan, repuestos Volkswagen Tiguan, Jujuy",
        },
      },
      {
        nombre: "Up",
        seo: {
          title: "Parabrisas Volkswagen Up - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Volkswagen Up en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Volkswagen Up, repuestos Volkswagen Up, Jujuy",
        },
      },
      {
        nombre: "Polo",
        seo: {
          title: "Parabrisas Volkswagen Polo - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Volkswagen Polo en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Volkswagen Polo, repuestos Volkswagen Polo, Jujuy",
        },
      },
    ],
    marca: "Renault",
    modelos: [
      {
        nombre: "Kwid",
        seo: {
          title: "Parabrisas Renault Kwid - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Renault Kwid en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Renault Kwid, repuestos Renault Kwid, Jujuy",
        },
      },
      {
        nombre: "Kangoo",
        seo: {
          title: "Parabrisas Renault Kangoo - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Renault Kangoo en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Renault Kangoo, repuestos Renault Kangoo, Jujuy",
        },
      },
      {
        nombre: "Duster",
        seo: {
          title: "Parabrisas Renault Duster - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Renault Duster en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Renault Duster, repuestos Renault Duster, Jujuy",
        },
      },
      {
        nombre: "Sandero",
        seo: {
          title: "Parabrisas Renault Sandero - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Renault Sandero en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords:
            "parabrisas Renault Sandero, repuestos Renault Sandero, Jujuy",
        },
      },
      {
        nombre: "Logan",
        seo: {
          title: "Parabrisas Renault Logan - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Renault Logan en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Renault Logan, repuestos Renault Logan, Jujuy",
        },
      },
    ],
  },
  {
    marca: "Fiat",
    modelos: [
      {
        nombre: "Cronos",
        seo: {
          title: "Parabrisas Fiat Cronos - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Fiat Cronos en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Fiat Cronos, repuestos Fiat Cronos, Jujuy",
        },
      },
      {
        nombre: "Toro",
        seo: {
          title: "Parabrisas Fiat Toro - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Fiat Toro en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Fiat Toro, repuestos Fiat Toro, Jujuy",
        },
      },
      {
        nombre: "Mobi",
        seo: {
          title: "Parabrisas Fiat Mobi - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Fiat Mobi en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Fiat Mobi, repuestos Fiat Mobi, Jujuy",
        },
      },
      {
        nombre: "Uno",
        seo: {
          title: "Parabrisas Fiat Uno - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Fiat Uno en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Fiat Uno, repuestos Fiat Uno, Jujuy",
        },
      },
      {
        nombre: "Argo",
        seo: {
          title: "Parabrisas Fiat Argo - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Fiat Argo en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Fiat Argo, repuestos Fiat Argo, Jujuy",
        },
      },
    ],
  },
  {
    marca: "Peugeot",
    modelos: [
      {
        nombre: "208",
        seo: {
          title: "Parabrisas Peugeot 208 - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Peugeot 208 en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Peugeot 208, repuestos Peugeot 208, Jujuy",
        },
      },
      {
        nombre: "2008",
        seo: {
          title: "Parabrisas Peugeot 2008 - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Peugeot 2008 en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Peugeot 2008, repuestos Peugeot 2008, Jujuy",
        },
      },
      {
        nombre: "3008",
        seo: {
          title: "Parabrisas Peugeot 3008 - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Peugeot 3008 en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Peugeot 3008, repuestos Peugeot 3008, Jujuy",
        },
      },
      {
        nombre: "208 GT",
        seo: {
          title: "Parabrisas Peugeot 208 GT - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Peugeot 208 GT en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Peugeot 208 GT, repuestos Peugeot 208 GT, Jujuy",
        },
      },
    ],
  }  ,
  {
    marca: "Honda",
    modelos: [
      {
        nombre: "Civic",
        seo: {
          title: "Parabrisas Honda Civic - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Honda Civic en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Honda Civic, repuestos Honda Civic, Jujuy",
        },
      },
      {
        nombre: "HR-V",
        seo: {
          title: "Parabrisas Honda HR-V - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Honda HR-V en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Honda HR-V, repuestos Honda HR-V, Jujuy",
        },
      },
      {
        nombre: "CR-V",
        seo: {
          title: "Parabrisas Honda CR-V - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Honda CR-V en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Honda CR-V, repuestos Honda CR-V, Jujuy",
        },
      },
      {
        nombre: "Fit",
        seo: {
          title: "Parabrisas Honda Fit - Repuestos y Servicios en Jujuy",
          description:
            "Encuentra parabrisas para Honda Fit en Jujuy. Ofrecemos repuestos originales y servicios de instalación de alta calidad.",
          keywords: "parabrisas Honda Fit, repuestos Honda Fit, Jujuy",
        },
      },
    ],
  }
];

export default MarcasModelos;
