import email from "../images/footer/email.png";
import instagram from "../images/footer/instagram.png";
import phone from "../images/footer/telephone.png";
import whatsapp from "../images/footer/whatsapp.png";

function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__2">
              <li>
                <span>CONTACTO</span>
              </li>
              <li>
                <a
                  href="https://api.whatsapp.com/send?phone=543884868847&text=Hola%20%F0%9F%91%8B"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={whatsapp} alt="whatsapp-logo" />
                  Whatsapp: &nbsp; +54 388 486 8847
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/parabrisas_jujuy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} alt="instagram-logo" />
                  Instagram: &nbsp; @parabrisas_jujuy
                </a>
              </li>
              <li>
                <a href="tel:543884868847" target="_blank" rel="noreferrer">
                  <img src={phone} alt="phone-logo" />
                  Telefono: &nbsp; +54 388 486 8847
                </a>
              </li>
              <li>
                <a
                  href="mailto: 
                alex53gaston@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={email} alt="email-logo" />
                  Email: &nbsp; alex53gaston@gmail.com
                </a>
              </li>
            </ul>
            <ul className="footer-content__2">
              <li>Horarios</li>
              <li>Lunes - Viernes: 9:00 a 13:00 y 14:00 a 18:00</li>
              <li>Sabado: 9:00 - 13:00</li>
              <li>Domingo: Cerrado</li>
            </ul>

            <ul className="footer-content__2">
              <div className="afip">
                <a
                  href="http://qr.afip.gob.ar/?qr=k2aaxomKzf_xh-qo4g-ubg,,"
                  target="_F960AFIPInfo"
                  rel="noopener noreferrer"
                >
                  <img
                    src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg"
                    alt="AFIP QR"
                    style={{ border: 0, width: "150px", height: "auto" }}
                  />
                </a>
              </div>
            </ul>
          </div>
        </div>
      </footer>
      <div className="by">
        <a
          style={{ fontSize: "14px" }}
          target="_blank"
          rel="noreferrer"
          href="https://southware.site/"
        >
          Desarrollado con ❤️ por Software of South ☁️
        </a>
      </div>
    </>
  );
}

export default Footer;
