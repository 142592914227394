import React from "react";
import { Helmet } from "react-helmet";
import MarcasModelos from "../components/MarcasModelos"; // Corrected path

const Parabrisa = ({ marca, modelo }) => {
  // Buscar la marca y el modelo específico en el archivo marcasYModelos.js
  const marcaEncontrada = MarcasModelos.find(
    (m) => m.nombre.toLowerCase() === marca.toLowerCase()
  );
  const modeloEncontrado = marcaEncontrada
    ? marcaEncontrada.modelos.find(
        (m) => m.nombre.toLowerCase() === modelo.toLowerCase()
      )
    : null;

  if (!marcaEncontrada || !modeloEncontrado) {
    return <div>Parabrisas no encontrado</div>;
  }

  return (
    <div>
      {/* SEO con react-helmet */}
      <Helmet>
        <title>{modeloEncontrado.meta.title}</title>
        <meta name="description" content={modeloEncontrado.meta.description} />
        <meta name="keywords" content={modeloEncontrado.meta.keywords} />
        <link rel="canonical" href={modeloEncontrado.meta.canonical} />
      </Helmet>
      {/* Información del parabrisas */}
      <h1>
        Parabrisas para {marca} {modelo}
      </h1>
      <p>Precio: ${modeloEncontrado.precio}</p>
      <p>En 3 cuotas: ${modeloEncontrado.cuotas.en3} sin interés</p>
      <p>En 6 cuotas: ${modeloEncontrado.cuotas.en6} sin interés</p>

      {/* Más detalles y opciones de compra */}
      <a href={modeloEncontrado.url}>Comprar ahora</a>

      {/* Aquí puedes agregar más contenido relacionado con el parabrisas, como imágenes, precios, etc. */}
    </div>
  );
};

export default Parabrisa;