import InstagramFeed from "./Instagram";
import ElfsightWidget from "./ElfsightWidget";
// import Img2 from "../images/testimonials/pfp1.jpg";
// import Img3 from "../images/testimonials/pfp2.jpg";

function Testimonials() {
  return (
    <>
      <InstagramFeed />

      <section className="testimonials-section" id="testimonials">
        <div className="container">
          
          <br />
          <div className="testimonials-content">
            <div className="testimonials-content__title">
              <h4>Opiniones de nuestros clientes</h4>
              <h2>Testimonios</h2>
              <p>
                Descubre el impacto positivo que hemos tenido en nuestros
                clientes leyendo sus testimonios. Nuestros clientes han
                experimentado nuestro servicio y resultados, y están ansiosos
                por compartir sus experiencias positivas contigo.
              </p>
            </div>

            <div className="about-main__text__icons">
            <div className="about-main__text__icons__box">
              <span>
                <h4>+4</h4>
                <p>Años de Experiencia</p>
              </span>
            </div>
            <div className="about-main__text__icons__box">
              <span>
                <h4>+485</h4>
                <p>Clients Satisfechos</p>
              </span>
            </div>
            <div className="about-main__text__icons__box">
              <span>
                <h4>+100</h4>
                <p>Modelos de Autos</p>
              </span>
            </div>
          </div>
          <br />
            <ElfsightWidget />
            {/* <div className="all-testimonials">
              <div className="all-testimonials__box">
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p>
                  "¡Excelente servicio! Instalé mi parabrisas con ellos y fue rápido y profesional. ¡Muy recomendable!"
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <img src={Img2} alt="user_img" />
                    <span>
                      <h4>Juan Pérez</h4>
                      <p>Jujuy, San Salvador de jujuy, Argentina</p>
                    </span>
                  </div>
                </div>
              </div>

              <div className="all-testimonials__box box-2">
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p>
                  "Compré un parabrisas para mi auto y el precio fue muy accesible. Además, la instalación a domicilio fue muy conveniente."
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <img src={Img3} alt="user_img" />
                    <span>
                      <h4>María López</h4>
                      <p>Perico, Jujuy, Argentina</p>
                    </span>
                  </div>
                </div>
              </div>
            </div> */}
            
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
