import { Link } from "react-router-dom";
import BgShape from "../images/hero/hero-bg.png";
import HeroCar from "../images/hero/main-car.png";
import WhatsappBoton from "../images/hero/boton-whatsapp.png";
import { useEffect, useState } from "react";
import up from "../images/navbar/up.png";
function Hero() {
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };

  const bookBtn = () => {
    document
      .querySelector("#booking-section")
      .scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.pageYOffset > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);
  return (
    <>
      <section id="home" className="hero-section">
        <div className="container">
          <img className="bg-shape" src={BgShape} alt="bg-shape" />
          <div className="hero-content">
            <div className="hero-content__text">
              {/* <h4>Nose</h4> */}
              <h1>
                <span>Parabrisas</span> Laterales y Cristales Jujuy
              </h1>
              <p>
                Somos expertos en la reparación y reemplazo de parabrisas, lunetas ,laterales y
                cristales. Atendemos a domicilio o en nuestro taller, con
                múltiples formas de pago disponibles, cuotas, y emitimos facturas.
              </p>
              <div className="hero-content__text__btns">
                <Link
                  onClick={bookBtn}
                  className=""
                  to="https://api.whatsapp.com/send?phone=543884868847&text=Hola%20%F0%9F%91%8B"
                >
                  <img
                    src={WhatsappBoton}
                    alt="contactanos por whatsapp"
                    width={210}
                  />{" "}
                  <i className=""></i>
                </Link>
                {/* <Link className="hero-content__text__btns__learn-more" to="/">
                  Learn More &nbsp; <i className="fa-solid fa-angle-right"></i>
                </Link> */}
              </div>
            </div>

            {/* img */}
            <img
              src={HeroCar}
              alt="car-img"
              className="hero-content__car-img"
            />
          </div>
        </div>

        {/* page up */}
        <div
          onClick={scrollToTop}
          className={`scroll-up ${goUp ? "show-scroll" : ""}`}
        >
          <img src={up} alt="up" width={50} />
        </div>
      </section>
    </>
  );
}

export default Hero;
