import MainImg from "../images/chooseUs/main.jpg";
import Box1 from "../images/chooseUs/icon1.png";
import Box2 from "../images/chooseUs/icon2.png";
import Box3 from "../images/chooseUs/icon3.png";

function ChooseUs() {
  return (
    <>
      <section className="choose-section">
        <div className="container">
          <div className="choose-container">
            <img
              className="choose-container__img"
              src={MainImg}
              alt=" windshield"

            />
            <div className="text-container">
              <div className="text-container__left">
                <h4>Por qué elegirnos?</h4>
                <h2>Nuestra Compromiso con la Calidad y el Servicio al Cliente</h2>
                <p>
                  En Parabrisas Jujuy, no solo nos enfocamos en la calidad de nuestros productos, sino también en la experiencia integral del cliente. Desde la primera consulta hasta la instalación final, nos aseguramos de brindarte un servicio excepcional que marque la diferencia.                </p>
                {/* <a href="#home">
                  Find Details &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a> */}
              </div>
              <div className="text-container__right">
                <div className="text-container__right__box">
                  <img src={Box1} alt="car-img" />
                  <div className="text-container__right__box__text">
                    <h4>Calidad y variedad de productos</h4>
                    <p>
                      Trabajamos con las mejores marcas del mercado, como Pilkington, Blindex, Sekurit y Favicur, garantizando que siempre obtendrás un producto de primera clase, adaptado a tu vehículo.
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box2} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Servicio personalizado y flexible</h4>
                    <p>
                      Ofrecemos instalación tanto en nuestro taller como a domicilio sin cargo adicional, dependiendo de la zona, para tu máxima comodidad y conveniencia.
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box3} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Facilidad de pago</h4>
                    <p>
                      Aceptamos todos los medios de pago emitimos facturas, para que puedas elegir la opción que mejor se adapte a tus necesidades.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChooseUs;
