import React, { useEffect, useState } from "react";
import axios from "axios";
import "./InstagramFeed.css";
import more from "../images/navbar/more.png";
import instagram from "../images/navbar/instagram-logo.png";

const InstagramFeed = () => {
  const [photos, setPhotos] = useState([]);
  const [error, setError] = useState(null);
  const [visiblePhotos, setVisiblePhotos] = useState(5); // Inicialmente mostrar 5 fotos
  const accessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN;

  const fetchCarouselPhotos = async (id) => {
    try {
      const response = await axios.get(
        `https://graph.instagram.com/${id}/children?fields=id,media_type,media_url&access_token=${accessToken}`
      );
      return response.data.data;
    } catch (err) {
      console.error(
        "Error fetching carousel photos:",
        err.response ? err.response.data : err.message
      );
      return [];
    }
  };

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,timestamp&access_token=${accessToken}`
        );

        const carouselPhotoData = await Promise.all(
          response.data.data
            .filter((photo) => photo.media_type === "CAROUSEL_ALBUM") // Filtrar solo los carruseles
            .map(async (photo) => {
              const carouselPhotos = await fetchCarouselPhotos(photo.id);
              return { ...photo, carouselPhotos: carouselPhotos.slice(0, 2) }; // Limitar a 2 imágenes
            })
        );

        setPhotos(carouselPhotoData);
      } catch (err) {
        console.error(
          "Error fetching Instagram photos:",
          err.response ? err.response.data : err.message
        );
        setError(
          "Error fetching Instagram photos. Please check your request and try again."
        );
      }
    };

    fetchPhotos();
  }, [accessToken]);

  const handleShowMore = () => {
    setVisiblePhotos((prevVisiblePhotos) => prevVisiblePhotos + 5);
  };

  return (
    <>
      <div className="testimonials-content__title">
        <h2>Nuestros últimos trabajos</h2>
      </div>
      <div className="instagram-feed">
        {error && <p className="error-message">{error}</p>}
        {photos.slice(0, visiblePhotos).map((photo) => (
          <div key={photo.id} className="instagram-photo">
            {photo.carouselPhotos.map((carouselPhoto) => (
              <a
                key={carouselPhoto.id}
                href={photo.permalink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={carouselPhoto.media_url} alt={photo.caption} />
                <p className="photo-date">
                  {new Date(photo.timestamp).toLocaleDateString()}
                </p>
              </a>
            ))}
          </div>
        ))}
      </div>

      <div className="button-container">
        {visiblePhotos < photos.length && (
          <button onClick={handleShowMore} className="button">
            <img src={more} alt="view more" width={15} />
            Ver más trabajos
          </button>
        )}
        <button className="button">
          <a href="https://www.instagram.com/parabrisas_jujuy/" target="_blank" rel="noopener noreferrer">
            {/* <img src={instagram} alt="instagram logo" width={25}/> */}
            Ir a nuestro Instagram
          </a>
        </button>
      </div>
    </>
  );
};

export default InstagramFeed;
