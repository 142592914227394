import { Link } from "react-router-dom";
import Logo from "../images/logo/logo-light-removebg.png";
import { useState } from "react";
import burger from "../images/navbar/burger.png";
import cross from "../images/navbar/close.png";

function Navbar() {
  const [nav, setNav] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setNav(false);
    }
  };

  return (
    <>
      <nav>
        {/* mobile */}
        <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
          <div onClick={openNav} className="mobile-navbar__close">
            <img src={cross} alt="cross" width={30} />
          </div>
          <ul className="mobile-navbar__links">
            <li>
              <a onClick={() => scrollToSection("home")}>Home</a>
            </li>
            {/* <li>
              <a onClick={() => scrollToSection("booking-section")}>
                Cobertura
              </a>
            </li> */}
            <li>
              <a onClick={() => scrollToSection("models")}>Lo que ofrecemos</a>
            </li>
            <li>
              <a onClick={() => scrollToSection("testimonials")}>Testimonios</a>
            </li>
            <li>
              <a onClick={() => scrollToSection("faq")}>Preguntas Frecuentes</a>
            </li>
            <li>
              <a onClick={() => scrollToSection("contact")}>Contacto</a>
            </li>
          </ul>
        </div>

        {/* desktop */}

        <div className="navbar">
          <div className="navbar__img">
            <a onClick={() => scrollToSection("home")}>
              <img src={Logo} alt="logo parabrisa jujuy" />
            </a>
          </div>
          <ul className="navbar__links">
            <li>
              <a className="home-link" onClick={() => scrollToSection("home")}>
                Inicio
              </a>
            </li>
            {/* <li>
              <a
                className="about-link"
                onClick={() => scrollToSection("booking-section")}
              >
                Cobertura
              </a>
            </li> */}
            <li>
              <a
                className="models-link"
                onClick={() => scrollToSection("models")}
              >
                Lo que ofrecemos
              </a>
            </li>
            <li>
              <a
                className="testi-link"
                onClick={() => scrollToSection("testimonials")}
              >
                Testimonios
              </a>
            </li>
            <li>
              <a className="team-link" onClick={() => scrollToSection("faq")}>
                Preguntas Frecuentes
              </a>
            </li>
            <li>
              <a
                className="contact-link"
                onClick={() => scrollToSection("contact")}
              >
                Contacto
              </a>
            </li>
          </ul>

          {/* mobile */}
          <div className="mobile-hamb" onClick={openNav}>
            <i className="fa-solid fa-bars">
              <img src={burger} width={40} alt="" />
            </i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
