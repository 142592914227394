import SelectCar from "../images/plan/icon1.png";
import Contact from "../images/plan/icon2.png";
import Drive from "../images/plan/icon3.png";

function PlanTrip() {
  return (
    <>
      <section className="plan-section" id="models">
        <div className="container">
          <div className="plan-container">
            <div className="plan-container__title">
              {/* <h3>Plan your trip now</h3> */}
              <h2>Que te ofrecemos</h2>
            </div>

            <div className="plan-container__boxes">
              <div className="plan-container__boxes__box">
                <img src={SelectCar} alt="icon_img" />
                <h3>Venta</h3>
                <p>
                  Venta de parabrisas, lunetas y laterales.
                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={Contact} alt="icon_img" />
                <h3>Marcas</h3>
                <p>
                  Trabajamos con todas las marcas y modelos: pilkington, blindex, sekurit, favicur, etc.
                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={Drive} alt="icon_img" />
                <h3>Instalación </h3>
                <p>
                  Instalación en nuestro taller o a domicilio sin cargo (dependiendo de la zona).
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PlanTrip;
